import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import Container from "../components/container"
import Section from "../components/section"
import GradientBackground from "../components/gradient-background"

const Btn = styled.a`
  display: block;
  padding: 20px 60px;
  border: 2px solid currentColor;
  text-align: center;
  margin: 30px 0;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
`

function Contact() {
  return (
    <>
      <SEO title="Contact" />
      <GradientBackground
        gradientValues={[
          "rgba(110, 128, 110, 1) 0%",
          "rgba(96, 173, 52, 1) 50%",
          "rgba(160, 247, 8, 1) 100%",
        ]}
      />
      <Section>
        <Container>
          <h1>Contact</h1>
          <Btn href="mailto:nicolas@pieces.live">Booking Worldwide</Btn>
          <Btn href="mailto:vassallo@meanwhile.nl">Booking Benelux</Btn>
          <Btn href="mailto:roberto@zerologistics.co.uk">Booking South America</Btn>
          <Btn href="mailto:nicolas@pieces.live">Press</Btn>
        </Container>
      </Section>
    </>
  )
}

export default Contact
